import AlternativeDocuments from "./NeedHelpAccordionItems/AlternativeDocuments";
import FileUploadRequirements from "./NeedHelpAccordionItems/FileUploadRequirements";
import {
  NeedHelpStyledAccordionComponent,
  NeedHelpStyledAccordionWrapper,
  NeedHelpTextStyle,
} from "./NeedHelpAccordionStyled";

const NeedHelpAccordion: React.FC = () => {


  return (
    <>
      <NeedHelpStyledAccordionWrapper mode="FAQ" role="accordion">
        <NeedHelpTextStyle>Need Help?</NeedHelpTextStyle>
        <NeedHelpStyledAccordionComponent
          key="1"
          title="File upload requirements"
          content={{
            mode: "FAQ",
            payload: <FileUploadRequirements />,
          }}
        />
        <NeedHelpStyledAccordionComponent
          key="2"
          title="Alternative methods to submit documents"
          className="AlternativeDocuments"
          content={{
            mode: "FAQ",
            payload: <AlternativeDocuments />,
          }}
        />
      </NeedHelpStyledAccordionWrapper>
    </>
  );
};

export default NeedHelpAccordion;
