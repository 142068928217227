import React, { useState } from "react";
import "./analytics/csgAnalytics";
import "./logrocket/logrocket";
import GlobalStyle from "./styles/global";
import Navigation from "./components/Navbar/Navigation";
import Footer from "./components/Footer/Footer";
import Form from "./components/Form/Form";
import { Route, Switch } from "react-router-dom";
import UploadDoc from "./components/UploadDoc/UploadDocument";
import { MetronomeProvider, muiBankTheme } from "@ally/metronome-ui";
import {
  ModalLayoutDivStyle,
  FixedDivStyled,
} from "./components/Form/FormStyles";
import { BodyContainer, Container } from "./AppStyled";

const App: React.FC = () => {
  const [modalState, setModalState] = useState("closed");
  const updateState = (arg: string) => {
    setModalState(arg);
  };

  return (
    <>
      <GlobalStyle />
      <MetronomeProvider theme={muiBankTheme}>
        <BodyContainer>
          <Container role="navigation">
            <Navigation updateModal={(arg) => updateState(arg)} />
          </Container>
          <FixedDivStyled
            itemProp={modalState === "opened" ? "fixed" : "static"}
          >
            <ModalLayoutDivStyle
              itemProp={modalState === "opened" ? "fixed" : "static"}
            ></ModalLayoutDivStyle>
            <Switch >
              <Route exact={true} path="/" render={() => <Form modal={modalState} />} />
              <Route exact={true} path="/upload" component={UploadDoc} />
            </Switch>
          </FixedDivStyled>
        </BodyContainer>
        <Footer modal={modalState} />
      </MetronomeProvider>
    </>
  );
};

export default App;
