import { Alert, Label } from "@ally/metronome-ui";
import { useLDClient } from "launchdarkly-react-client-sdk";
import LogRocket from "logrocket";
import React from "react";
import { useHistory } from "react-router-dom";
import { createDefaultMaskGenerator } from "../../library/react-hook-mask";
import DocDocService from "../../services/docDoc/docDocService";
import ResourceAccordion from "../Accordion/ResourceAccordion";
import { childProps } from "../Model/ChildProps";
import {
  AccordionStyle,
  AlertStyle,
  AlignColumn,
  BoxStyle,
  ButtonContainer,
  ButtonStyle,
  Heading,
  LabelStyle,
  MaskInputStyle,
  PhoneErrorDiv,
  SideBySide,
  SubHeader,
  TextInputStyle,
} from "./FormStyles";

const Form: React.FC<childProps> = (props: childProps) => {
  const history = useHistory();

  const emailPattern = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@([\w-]+\.)+([\w-]{2,4})$/
  );
  const [initialClick, setInitialClick] = React.useState(false);
  const ldClient = useLDClient();
  const [maintenanceAlert, setMaintenanceAlert] = React.useState(false);

  React.useEffect(() => {
    setMaintenanceAlert(ldClient.variation('maintenance-mode', false));
    window.allytm.event("pageView", "CSG:Credit Line Application:Intake");
    if (window.innerWidth > 639) {
      document.getElementById("ally-logo").focus();
    } else {
      const hamMenu = document.querySelector(
        "[data-test-id='hamburger-menu']"
      ) as HTMLElement | null;
      hamMenu.focus();
    }
  }, []);

  const [phone, setPhone] = React.useState("");
  const [phoneErrorMsg, setPhoneErrorMsg] = React.useState("");
  const [ApplicantBusinessName, setApplicantBusinessName] = React.useState("");
  const [ApplicantBusinessNameError, setApplicantBusinessNameError] =
    React.useState("");
  const businessNameErrorMsg = "Enter company name to continue.";

  const [fullNameOfApplicant, setFullNameOfApplicant] = React.useState("");
  const [fullNameOfApplicantError, setFullNameOfApplicantError] =
    React.useState("");

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [isPullingStatus, setIsPullingStatus] = React.useState(false);
  const [alert, setAlert] = React.useState(false);

  let formError = false;
  const docDocService = DocDocService.getInstance();
  const businessNameInputRef = React.useRef<HTMLInputElement | null>(null);
  const fullNameInputRef = React.useRef<HTMLInputElement | null>(null);
  const emailInputRef = React.useRef<HTMLInputElement | null>(null);
  const phoneInputRef = React.useRef<HTMLInputElement | null>(null);
  const errorRef = React.useRef(null);

  const maskGenerator = createDefaultMaskGenerator("1-999-999-9999");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.id === "ApplicantBusinessName") {
      setApplicantBusinessName(
        event.target.value.replace(/[.@~():;"<,>[\]\\]/gi, "").trimStart()
      );
      if (
        initialClick &&
        event.target.value.length > 0 &&
        event.target.value.length < 255
      ) {
        setApplicantBusinessNameError("");
      }
    }
    if (event.target.id === "FullNameOfApplicant") {
      setFullNameOfApplicant(
        event.target.value.replace(/[.@~():;"<,>[\]\\]/gi, "").trimStart()
      );
      if (
        initialClick &&
        event.target.value.length > 0 &&
        event.target.value.length < 255
      ) {
        setFullNameOfApplicantError("");
      }
    }

    if (event.target.id === "ApplicantEmail") {
      setEmail(event.target.value.replace(/[/=?^`{}|():;"<,> [\]\\/]/gi, ""));
      if (
        initialClick &&
        emailPattern.test(event.target.value) &&
        event.target.value.length > 0
      ) {
        setEmailError("");
      }
    }

    if (event.target.id === "ApplicantPhone") {
      setPhone(event.target.value);
      if (initialClick && phone.replace(/\s/g, "").length >= 13) {
        setPhoneErrorMsg("");
      }
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (
      initialClick &&
      event.target.id === "ApplicantBusinessName" &&
      event.target.value.length <= 0
    ) {
      setApplicantBusinessNameError(businessNameErrorMsg);
    }

    if (
      initialClick &&
      event.target.id === "FullNameOfApplicant" &&
      event.target.value.length <= 0
    ) {
      setFullNameOfApplicantError("Enter contact full name to continue.");
    }

    if (
      initialClick &&
      event.target.id === "ApplicantEmail" &&
      (event.target.value.length <= 6 || !emailPattern.test(event.target.value))
    ) {
      setEmailError("Enter email address to continue.");
    }
  };

  const handlePhoneOnChange = (event: any): void => {
    setPhone(event);
    if (initialClick && event.length === 10) {
      setPhoneErrorMsg("");
    }
  };

  const formatPhoneNumber = (phnum: string) => {
    let newph1 = phnum.substring(0, 3);
    let newph2 = phnum.substring(3, 6);
    let newph3 = phnum.substring(6, 10);
    let newph = "1-" + newph1 + "-" + newph2 + "-" + newph3;
    return newph;
  };

  const handlePhoneBlur = (event: any) => {
    if (initialClick && phone.length !== 10) {
      formError = true;
      setPhoneErrorMsg("Enter phone to continue.");
    }
  };

  const handleFormError = () => {
    let customErrorList = [];
    if (phone.length !== 10) {
      formError = true;
      setPhoneErrorMsg("Enter phone to continue.");
      customErrorList.push("Enter phone to continue.");
      phoneInputRef.current?.focus();
    }

    if (!emailPattern.test(email) || email.length <= 6) {
      formError = true;
      setEmailError("Enter email address to continue.");
      customErrorList.push("Enter email address to continue.");
      emailInputRef.current?.focus();
    }

    if (fullNameOfApplicant.length <= 0) {
      formError = true;
      setFullNameOfApplicantError("Enter contact full name to continue.");
      customErrorList.push("Enter contact full name to continue.");
      fullNameInputRef.current?.focus();
    }

    if (ApplicantBusinessName.length <= 0 || ApplicantBusinessName === "") {
      formError = true;
      setApplicantBusinessNameError(businessNameErrorMsg);
      customErrorList.push(businessNameErrorMsg);
    }

    customErrorList.forEach((errorString) =>
      window.allytm.event("customError", errorString, 400)
    );
  };

  const handleFormErrorFocus = () => {
    if (phone.length !== 10) {
      phoneInputRef.current?.focus();
    }

    if (!emailPattern.test(email) || email.length <= 6) {
      emailInputRef.current?.focus();
    }

    if (fullNameOfApplicant.length <= 0) {
      fullNameInputRef.current?.focus();
    }

    if (ApplicantBusinessName.length <= 0 || ApplicantBusinessName === "") {
      businessNameInputRef.current?.focus();
    }
  };

  const onClick = (event: any) => {
    event.preventDefault();
    setInitialClick(true);

    const userInfo = {
      CompanyName: ApplicantBusinessName,
      ApplicantName: fullNameOfApplicant,
      Email: email,
      PhoneNumber: formatPhoneNumber(phone),
      ContactName: fullNameOfApplicant,
    };

    handleFormError();
    if (!formError) {
      LogRocket.startNewSession();
      LogRocket.identify(userInfo.CompanyName);
      ldClient.identify({ key: userInfo.CompanyName })

      setIsPullingStatus(true);
      docDocService
        .getStatus(userInfo.PhoneNumber, userInfo.Email)
        .then((value) => {
          setIsPullingStatus(false);
          // Use local storage to tell destination not to reload upload status initially
          localStorage.setItem("doNotRefreshUploadStatus", "true");
          history.push({
            pathname: "/upload",
            state: {
              userInfo: userInfo,
              uploadStatus: value,
            },
          });
        })
        .catch((err) => {
          setIsPullingStatus(false);
          setAlert(true);
          errorRef.current?.focus();
          window.allytm.event(
            "customError",
            "We can't complete your request right now. Try again later, or call us for help.",
            500
          );
          setApplicantBusinessName("");
          setFullNameOfApplicant("");
          setEmail("");
          setPhone("");
        });
    } else {
      setIsPullingStatus(false);
      handleFormErrorFocus();
    }
  };

  return (
    <main>
      {maintenanceAlert && (
        <AlertStyle>
          <Alert
            variant="info"
            cancellable
            contained
            backgroundWhite
            ref={errorRef}
            handleClose={() => setMaintenanceAlert(false)}
            body="Maintenance Mode.  We are currently making updates to this application.  Please try again later."
          />
        </AlertStyle>
      )}
      {alert && (
        <AlertStyle>
          <Alert
            variant="error"
            cancellable
            contained
            backgroundWhite
            ref={errorRef}
            handleClose={() => setAlert(false)}
            body="We can't complete your request right now. Try again later, or call us for help."
          />
        </AlertStyle>
      )}
      <SideBySide>
        <AlignColumn>
          <Heading color="#2A2A2A">
            Ally Commercial Credit Line Application
          </Heading>
          <SubHeader>
            Complete the fields to begin uploading the documents needed for the
            application. Enter the applicant's information as it will appear on
            the application.
          </SubHeader>
          <BoxStyle ml="1rem" p={["sm", "md"]} height="100%">
            <form onSubmit={onClick}>
              <Label textTransform="uppercase" htmlFor="ApplicantBusinessName">
                <LabelStyle>Company Name</LabelStyle>
              </Label>
              <TextInputStyle
                my=".1rem"
                width="298px"
                height="34px"
                id="ApplicantBusinessName"
                required={true}
                aria-required="true"
                ref={businessNameInputRef}
                maxLength={60}
                inputType="text"
                errorMessage={ApplicantBusinessNameError}
                value={ApplicantBusinessName}
                onBlur={handleBlur}
                onChange={handleChange}
                inputFieldWidth="100%"
              />
              <Label textTransform="uppercase" htmlFor="FullNameOfApplicant">
                <LabelStyle>Contact Full Name</LabelStyle>
              </Label>
              <TextInputStyle
                my=".1rem"
                width="298px"
                id="FullNameOfApplicant"
                ref={fullNameInputRef}
                aria-required="true"
                required={true}
                maxLength={60}
                inputType="text"
                errorMessage={fullNameOfApplicantError}
                value={fullNameOfApplicant}
                onBlur={handleBlur}
                onChange={handleChange}
                inputFieldWidth="100%"
                data-private
              />
              <Label textTransform="uppercase" htmlFor="ApplicantEmail">
                <LabelStyle>Email</LabelStyle>
              </Label>
              <TextInputStyle
                my=".1rem"
                width="298px"
                id="ApplicantEmail"
                ref={emailInputRef}
                minLength={6}
                maxLength={64}
                required={true}
                aria-required="true"
                type="email"
                inputType="text"
                errorMessage={emailError}
                value={email}
                onBlur={handleBlur}
                onChange={handleChange}
                inputFieldWidth="100%"
                autoFocus={false}
                data-private
              />
              <Label textTransform="uppercase" htmlFor="ApplicantPhone">
                <LabelStyle>Phone</LabelStyle>
              </Label>
              <MaskInputStyle
                style={{
                  border: `${
                    phoneErrorMsg ? "1px solid red" : "1px solid #959595"
                  }`,
                }}
                aria-required="true"
                id="ApplicantPhone"
                maskGenerator={maskGenerator}
                ref={phoneInputRef}
                value={phone}
                onChange={handlePhoneOnChange}
                onBlur={handlePhoneBlur}
                data-private
              ></MaskInputStyle>
              {phoneErrorMsg.length > 0 && (
                <PhoneErrorDiv>{phoneErrorMsg}</PhoneErrorDiv>
              )}

              <ButtonContainer>
                <ButtonStyle
                  allytmln="ContinueBtn"
                  onClick={onClick}
                  content="Continue"
                  variant="primary"
                  type="submit"
                  withAllyTM="primary-button-test"
                  isLoading={isPullingStatus}
                ></ButtonStyle>
              </ButtonContainer>
            </form>
          </BoxStyle>
        </AlignColumn>
        <AccordionStyle>
          <ResourceAccordion />
        </AccordionStyle>
      </SideBySide>
    </main>
  );
};

export default Form;
