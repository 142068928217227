import {
  NeedHelpStyledAccordionComponent,
  NeedHelpStyledAccordionWrapper,
  NeedHelpTextStyle,
} from "./MobileNeedHelpAccordionStyled";
import AlternativeDocuments from "./NeedHelpAccordionItems/AlternativeDocuments";
import FileUploadRequirements from "./NeedHelpAccordionItems/FileUploadRequirements";

const MobileNeedHelpAccordion: React.FC = () => {

  return (
    <>
      <NeedHelpStyledAccordionWrapper mode="FAQ">
        <NeedHelpTextStyle>Need Help?</NeedHelpTextStyle>
        <NeedHelpStyledAccordionComponent
          key="1"
          title="File upload requirements"
          content={{
            mode: "FAQ",
            payload: <FileUploadRequirements />,
          }}
        />
        <NeedHelpStyledAccordionComponent
          key="2"
          title="Alternative methods to submit documents"
          className="AlternativeDocuments"
          content={{
            mode: "FAQ",
            payload: <AlternativeDocuments />,
          }}
        />
      </NeedHelpStyledAccordionWrapper>
    </>
  );
};

export default MobileNeedHelpAccordion;
