import { muiCancelFill, muiPlus } from "@ally/metronome-icons/dist/cjs";
import { TextBody } from "@ally/metronome-ui";
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ErrorNotificationStyle,
  SuccessfullyUploadDivStyle,
  LabelContainer,
  LabelStyle,
  CanceledStyledIcon,
  ButtonContainer,
  ButtonStyle,
  SubmittedDocDiv,
  SubmittedText,
  SubmittedFileStyle,
  UploadButton,
  UploadPlusIcon,
  SubmittedFilesContainer,
  UploadContainer,
  ChildUploadContainer,
} from "./RequiredDocAccordionStyled";
import { useLocation } from "react-router-dom";
import DocDocService from "../../services/docDoc/docDocService";
import { UploadUrlRequest } from "../../services/docDoc/model/uploadUrlRequest";
import { AlertContext } from "../../context/alertContext";
import { UploadError } from "./UploadComponent/UploadError";
import { DocSubmitTrackerService } from "../../services/docSubmitTrackerService";
import { StringFormatUtility } from "../../Utility/string-format.utility";
import React from "react";

export const UploadButtonContent = () => {
  return (
    <>
      <UploadPlusIcon muiIcon={muiPlus} size="sm" fill="#0071C4" ariaHidden />
      <div>Upload file</div>
    </>
  );
};
export interface fromProps {
  from?: string;
  arialLabel?: string;
}

export const UploadFileComponent: React.FC<fromProps> = (props: fromProps) => {
  const userInfo = (useLocation().state as any).userInfo;
  const specialChars = /[`!@#$%^&*()+=[\]{};':"\\|,<>/?~]/;
  const allowedExtensions = /(pdf|png|jpg|heic)$/i;

  // References
  const inputFile = useRef(null);

  // Context
  const { setAlert } = useContext(AlertContext);

  // States
  const [submittedFiles, setSubmittedFiles] = useState([]);
  const [allFiles, setUnsubmittedFiles] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const submittedRef = useRef(null);
  const errorRef = useRef([]);
  const parentRef = useRef(null);
  const uploadContainerRef = useRef(null);
  const [currentFilesBeingUpload, setCurrentFilesBeingUpload] = useState([])

  const uploadButtonRef = useRef(null);

  // Error Messages
  const maxUploadMb = process.env.REACT_APP_MAX_UPLOAD_MB
    ? parseInt(process.env.REACT_APP_MAX_UPLOAD_MB)
    : 25;

  const docSubmitTrackerService = DocSubmitTrackerService.getInstance();

  const acceptedFileTypeErrorMessage = `We only accept PDF, PNG, JPG and HEIC`;
  const fileSizeErrorMessage = `The maximum file size is ${maxUploadMb} MB. Please reduce the file size and try again`;
  const fileNameErrorMessage = `Your filename can't contain special characters, such as: @ # $ % & < > " / \\`;
  const fileNameErrorMessageAriaLabel = `Your filename can't contain special characters, such as: @ # $ % & < > double quotes, forward slash, back slash or other special characters `;
  const fileNameLengthErrorMessage = `Your filename can't contain more than 60 characters`;

  const getAccordianTrackingId = (clickReason: string) => {
    const docType = props.from ?? "undefined";
    return `${docType}:${clickReason}`;
  };

  const onFileChange = (event: any) => {
    if (inputFile && inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.click();
    }
  };

  const trackSuccessfulFileSubmissions = (
    filename: string,
    accordionSectionName: string | undefined
  ): void => {
    accordionSectionName = accordionSectionName ?? "undefined";
    const payload = {
      name: "docUploadSuccessEvent",

      type: "CustomEvent",
      category: "UserInteraction:CustomEvent",

      attr: { sectionName: `${accordionSectionName}:docUpload` },
    };
    window.allytm.event("customEvent", payload);
  };

  const validateFile = (file: any) => {
    const filename = file.name;
    const filesize = file.size;
    let parts = filename.split(".");
    const fileType = parts[parts.length - 1].toLowerCase();
    let errorObject = {} as any;

    let customErrorList: string[] = [];

    if (filename.length > 60) {
      errorObject["fileNameLengthError"] = true;
      customErrorList.push(fileNameLengthErrorMessage);
    }

    if (filesize > maxUploadMb * 1024 * 1024) {
      errorObject["fileSizeError"] = true;
      customErrorList.push(fileSizeErrorMessage);
    }

    const localFileTypeError = !allowedExtensions.exec(fileType);
    if (localFileTypeError) {
      errorObject["fileTypeError"] = true;
      customErrorList.push(acceptedFileTypeErrorMessage);
    }

    const localFileNameError = specialChars.test(filename);
    if (localFileNameError) {
      errorObject["fileNameError"] = true;
      customErrorList.push(fileNameErrorMessage);
    }

    if (customErrorList.length > 0) {
      errorObject["emitUserBasedAnalyticErrors"] = () => {
        customErrorList.forEach((errorString) =>
          window.allytm.event("customError", errorString, 400)
        );
      };
    }
    return errorObject;
  };

  const handleFileUpload = (event: any) => {
    let fileArray = [];
    let currentUploadFileArray = [];

    // If we previously submitted, keep file array empty and reset.
    if (hasSubmitted) {
      setHasSubmitted(false);
    }

    // If we haven't previously submitted, populate file array with pending files.
    else {
      fileArray = Object.assign([], allFiles);
    }

    const { files } = event.target as any;
    

    if (files && files.length) {
      for (const file of files) {
        const fileErrors = validateFile(file);

        if (Object.keys(fileErrors).length > 0) {
          file["errorObject"] = fileErrors;
        }

        fileArray.push(file);
        currentUploadFileArray.push(file)
      }
    }
    setCurrentFilesBeingUpload(currentUploadFileArray);
    setUnsubmittedFiles(fileArray);
    docSubmitTrackerService.setCategoryHasPendingFiles(props.from, true);
  };

  const onClickSubmit = async () => {
    const docDocService = DocDocService.getInstance();
    const validFiles = allFiles.filter((file) => !file.errorObject);
    const invalidFiles = allFiles.filter((file) => file.errorObject);

    let submitted: File[] = [];

    for (const file of validFiles) {
      const urlRequest = new UploadUrlRequest(
        file.name,
        userInfo.CompanyName,
        userInfo.PhoneNumber,
        userInfo.Email,
        props.from,
        userInfo.ContactName
      );

      try {
        setIsUploading(true);
        await docDocService.uploadDocument(urlRequest, file);
        setIsUploading(false);
        trackSuccessfulFileSubmissions(file.name, props.from);
        submitted.push(file);
      } catch (error) {
        console.log(error);
        // Set alert dialog to pop up on failed API calls
        window.allytm.event("customError", error, 500);
        setAlert(true);
        setIsUploading(false);
      }
    }
    setSubmittedFiles([...submitted, ...submittedFiles]);
    setUnsubmittedFiles(invalidFiles);
    invalidFiles.forEach(({ errorObject }) => {
      errorObject.emitUserBasedAnalyticErrors();
    });
    setHasSubmitted(true);
    docSubmitTrackerService.setCategoryHasPendingFiles(props.from, false);
    if (invalidFiles.length > 0) {
      if (parentRef.current) {
        parentRef.current.children[0].focus();
      }
    } else {
      submittedRef.current?.focus();
    }
  };

  const cancelUpload = (item: any, index: any) => {
    const newFileArray = allFiles.filter((file) => file !== item);
    if (newFileArray.length <= 0) {
      uploadButtonRef.current?.focus();
    } else if (allFiles.length - 1 === index) {
      uploadContainerRef.current.children[0].childNodes[
        newFileArray.length - 1
      ].focus();
    }

    setUnsubmittedFiles(newFileArray);
    const pendingFilesRemain = newFileArray.length > 0;
    docSubmitTrackerService.setCategoryHasPendingFiles(
      props.from,
      pendingFilesRemain
    );
  };

  return (
    <>
      <UploadContainer ref={uploadContainerRef}>
        {allFiles.length > 0 && (
          <ul ref={parentRef} id="unordered_list">
            {allFiles.map((item, index) => {
              return (
                <ChildUploadContainer
                  key={index}
                  ref={errorRef.current[index]}
                  tabIndex={-1}
                  id={`upload-list-item-${index}`}
                >
                  {hasSubmitted && item["errorObject"] && (
                    <UploadError item={item} />
                  )}
                  {hasSubmitted &&
                    item["errorObject"] &&
                    item["errorObject"]["fileNameLengthError"] && (
                      <ErrorNotificationStyle>
                        {fileNameLengthErrorMessage}
                      </ErrorNotificationStyle>
                    )}
                  {hasSubmitted &&
                    item["errorObject"] &&
                    item["errorObject"]["fileSizeError"] && (
                      <ErrorNotificationStyle>
                        {fileSizeErrorMessage}
                      </ErrorNotificationStyle>
                    )}
                  {hasSubmitted &&
                    item["errorObject"] &&
                    item["errorObject"]["fileTypeError"] && (
                      <ErrorNotificationStyle id="filenameError">
                        {acceptedFileTypeErrorMessage}
                      </ErrorNotificationStyle>
                    )}
                  {hasSubmitted &&
                    item["errorObject"] &&
                    item["errorObject"]["fileNameError"] && (
                      <ErrorNotificationStyle aria-label={fileNameErrorMessageAriaLabel}>
                        {fileNameErrorMessage}
                      </ErrorNotificationStyle>
                    )}
                  {!hasSubmitted && (
                    <SuccessfulEx
                      deleteFile={(item) => cancelUpload(item, index)}
                      index={index}
                      item={item}
                      identifier={`${index}`}
                      currentFilesUpload={currentFilesBeingUpload ? currentFilesBeingUpload : null}
                      allFilesReadyToSubmit={allFiles ? allFiles : null}
                      ref={parentRef}
                    />
                  )}
                </ChildUploadContainer>
              );
            })}
          </ul>
        )}
        <TextBody tag="div" size="md">
          <ButtonContainer>
            {!isUploading && (
              <UploadButton
                allytmln={getAccordianTrackingId("uploadFile")}
                ref={uploadButtonRef}
                onClick={onFileChange}
                aria-label={props.arialLabel || "upload documents"}
                content={<UploadButtonContent />}
                className={props.from}
                variant="primary"
                data-test-id={`upload_${props.from}`}
                type="submit"
                withAllyTM="primary-button-test"
              ></UploadButton>
            )}
          </ButtonContainer>
          <input
            style={{ display: "none" }}
            ref={inputFile}
            data-test-id={
              props.from !== "undefined"
                ? `${props.from}_inputFile`
                : "uploadCaptureInputFile"
            }
            onChange={handleFileUpload}
            type="file"
            multiple
          />
        </TextBody>
        {!hasSubmitted && allFiles.length > 0 && (
          <ButtonContainer>
            <ButtonStyle
              allytmln={getAccordianTrackingId("submit")}
              onClick={onClickSubmit}
              content="Submit"
              variant="primary"
              id={`submit_${props.from}`}
              data-test-id={`submit_${props.from}`}
              type="submit"
              withAllyTM="primary-button-test"
              isLoading={isUploading}
            ></ButtonStyle>
          </ButtonContainer>
        )}
      </UploadContainer>
      {submittedFiles.length > 0 && (
        <SubmittedDocDiv
          data-test-id="submitted_container_1"
          ref={submittedRef}
          tabIndex={-1}
        >
          <SubmittedText>Submitted documents:</SubmittedText>
          <SubmittedFilesContainer>
            {submittedFiles.map((item, index) => {
              return (
                <div key={index}>
                  <SubmittedFileStyle
                    data-test-id={"submitted_file_" + item["name"]}
                  >
                    {`${
                      item["name"]
                    } (${StringFormatUtility.generateFileSizeString(
                      item["size"]
                    )})`}
                  </SubmittedFileStyle>
                </div>
              );
            })}
          </SubmittedFilesContainer>
        </SubmittedDocDiv>
      )}
    </>
  );
};

interface successfulChildProps {
  item: any;
  index: any;
  identifier: any;
  deleteFile: (arg: string) => void;
  currentFilesUpload?: any;
  allFilesReadyToSubmit?: any;
}

export const SuccessfulEx = React.forwardRef(
  (props: successfulChildProps, ref: any): React.ReactElement => {
    const fileName = props.item.name;
    const fileSize = StringFormatUtility.generateFileSizeString(
      props.item.size
    );
    const displayName = `${fileName} (${fileSize})`;
    const uploadedRef = useRef(null);

    useEffect(() => {
      if(JSON.stringify(props.currentFilesUpload) === JSON.stringify(props.allFilesReadyToSubmit)){
        if (ref.current) {
          ref.current.firstChild.focus();
        } 
      } else {
        const childNode = ref.current.childNodes;
        for( let child of childNode){
          let childName = child.outerText;
          if(childName.includes(props.currentFilesUpload[0].name)){
            child.focus();
          }
        }
      }

      if (props.currentFilesUpload.length === 1){
        uploadedRef.current?.focus();
      }
    }, []);

    return (
      <SuccessfullyUploadDivStyle ref={uploadedRef} tabIndex={-1}>
        <LabelContainer>
          <LabelStyle>{displayName}</LabelStyle>
          <CanceledStyledIcon
            tabIndex={0}
            aria-label={"remove " + props.item.name}
            muiIcon={muiCancelFill}
            id={"svg_" + props.item.name}
            data-test-id={"svg_" + props.item.name}
            size="md"
            fill="#0071C4"
            mt="5px"
            onClick={() => props.deleteFile(props.item)}
            onKeyPress={() => props.deleteFile(props.item)}
          />
        </LabelContainer>
      </SuccessfullyUploadDivStyle>
    );
  }
);
